.input-container {
    position: relative;
  }
  
  .tooltip {
    display: "block";
    position: absolute;
    background-color: red;
    color: white;
    padding: 8px;
    border-radius: 6px;
    z-index: 1;
    top: -15px;
    left: 70px;
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 8px;
    border-color: red transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  