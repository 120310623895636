@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  a {
    @apply text-blue-600 underline;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
  color: #2c2f35;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

button {
  font-family: "Manrope", sans-serif;
}

input {
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #aeb6bf;
  padding: 0.5rem;
  font-family: "Manrope", sans-serif;
}

textarea::placeholder {
  font-weight: bold;
}

textarea {
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.3rem;
  width: calc(100% - 0.6rem);
  box-shadow: none;
  border: 1px solid #dfdfdf;
  /* padding: 0.5rem; */
  resize: none;
  font-family: "Manrope", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
